import React, {Component} from "react";
import {Grid, Typography} from "@material-ui/core";
import clsx from "clsx";
import {withStyles} from "@material-ui/core/styles";
import {loginStyles} from "../styles";

class CCOrDivider extends Component {
    render() {
        const {classes} = this.props;
        return <Grid item>
            <div className={clsx(classes.divider, classes.fixedWidth)}>
                <div className={classes.dividerPiece} style={{marginRight: "8px"}}/>
                <Typography variant="body2">Or</Typography>
                <div className={classes.dividerPiece} style={{marginLeft: "8px"}}/>
            </div>
        </Grid>
    }
}

export default withStyles(loginStyles)(CCOrDivider);