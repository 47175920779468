import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "../styles";
import {Button, CircularProgress, Container, Divider, Grid, Menu, MenuItem, Paper} from "@material-ui/core";
import {format} from "date-fns";
import clsx from "clsx";
import Typography from "@material-ui/core/Typography";
import DOMPurify from "dompurify";
import {renderTo} from "../to";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faReply} from "@fortawesome/free-solid-svg-icons";
import CCReply from "../reply";
import CCViewer from "../viewers/viewer";
import CCAttachment from "./attachment";
import {isMobile} from "react-device-detect";
import CCFile from "./file";
import {checkNotNull} from "../null";

class CCEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            showAllTo: false,
            showReply: false,
            activeAttachment: null,
            replyAll: true,
        };
        this.showTo = this.showTo.bind(this);
        this.viewAttachment = this.viewAttachment.bind(this);
        this.closeReply = this.closeReply.bind(this);
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    showTo() {
        this.setState({
            showAllTo: true,
        })
    }

    viewAttachment(attachment) {
        this.setState({
            activeAttachment: attachment
        });
    }

    cleanHtml(showFull) {
        let html = this.props.email.html;
        let parser = new DOMParser();
        let htmlDoc = parser.parseFromString(html, 'text/html');
        if (!showFull) {
            let el = htmlDoc.getElementById("CanaryBlockquote")
            if (el !== null) {
                el.remove();
            }
        }
        return DOMPurify.sanitize(htmlDoc.documentElement.outerHTML);
    }

    renderAttachments(classes) {
        return <Grid item xs={12}>
            <div className={classes.rowFull} style={{flexWrap: "wrap"}}>
                {
                    this.props.email.attachments.map(item => {
                        return <CCAttachment attachment={item} view={this.viewAttachment}/>;
                    })
                }
            </div>
        </Grid>;
    }

    renderFiles(classes) {
        return <Grid item xs={12}>
            <div className={classes.rowFull} style={{flexWrap: "wrap"}}>
                {
                    this.props.object.files.map(item => {
                        return <CCFile user={this.props.user} authKey={this.props.authKey} emlKey={this.props.emlKey}
                                       file={item} view={this.viewAttachment} fetchAccessKey={this.props.fetchAccessKey}/>;
                    })
                }
            </div>
        </Grid>;
    }

    replyNow(e) {
        if (checkNotNull(this.props.email.to) && this.props.email.to.value.length > 1) {
            this.setState({
                replyAnchor: e.currentTarget
            })
        } else {
            this.setState({
                showReply: true
            });
        }
    }

    renderReplyButton(classes) {
        if (isMobile) {
            return <div className={classes.rowFull}>
                <div className={clsx(classes.buttonRound, classes.right, classes.column)}
                     style={{alignItems: "center", justifyContent: "center"}}
                     onClick={(e) => {
                         this.replyNow(e);
                     }}>
                    <FontAwesomeIcon icon={faReply}/>
                </div>
            </div>
        } else {
            return <div className={classes.rowFull}>
                <Button className={clsx(classes.button, classes.fixedWidth)}
                        variant="contained"
                        onClick={(e) => {
                            this.replyNow(e);
                        }}>
                    <FontAwesomeIcon icon={faReply}/>&nbsp;&nbsp;Reply
                </Button>
            </div>
        }
    }

    closeReply() {
        this.setState({
            showReply: false,
            replyAll: true,
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        if (this.state.activeAttachment) {
            let attachment = this.state.activeAttachment;
            return <CCViewer attachment={attachment} back={() => {
                this.setState({activeAttachment: null})
            }}/>
        }

        let date = new Date(this.props.object.metadata.created * 1000.0);
        let dateStr = format(date, "do MMM yy 'at' h:mm aaa");

        let to = this.props.object.metadata.to;
        let toHtml = renderTo(classes, to, this.props.user.email, this.state.showAllTo, () => {
            this.showTo();
        });
        let html = this.cleanHtml(false);
        let from = this.props.object.metadata.owner;
        if (from === this.props.user.email) {
            from = "Me";
        }

        return <Paper className={classes.paperLight} style={{overflow: "hidden"}}>
            <div className={classes.paperBackground}>
                <div className={classes.paperHeader}>
                    <span>
                        Secure Email
                    </span>
                </div>
            </div>
            <div className={classes.paperNarrow} style={{color: "#000", background: "#fff"}}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <div className={clsx(classes.rowSm, classes.rowEnd)}>
                                        <span className={classes.paperFont}>
                                        <b>{from + " "}</b>
                                        to {toHtml}
                                    </span>
                            <Typography style={{flexShrink: 0}} variant="body2" className={classes.rightSm}>
                                {dateStr}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={12} style={{}}>
                        <Divider flexItem orientation="horizontal"
                                 style={{
                                     height: "1px",
                                     marginTop: "10px",
                                     marginBottom: "10px",
                                     background: "#f1f1f1"
                                 }}/>
                    </Grid>
                    <Grid item xs={12} style={{background: "#ffffff", color: "#000"}}>
                        {<div dangerouslySetInnerHTML={{__html: html}}/>}
                    </Grid>
                    {
                        checkNotNull(this.props.email.attachments) && this.props.email.attachments.length > 0 &&
                        this.renderAttachments(classes)
                    }
                    {
                        checkNotNull(this.props.object.files) && this.props.object.files.length > 0 &&
                        this.renderFiles(classes)
                    }
                    {
                        !this.state.showReply && <Grid item xs={12}>
                            {
                                this.renderReplyButton(classes)
                            }
                            {
                                <Menu
                                    id="simple-menu"
                                    anchorEl={this.state.replyAnchor}
                                    keepMounted
                                    open={Boolean(this.state.replyAnchor)}
                                    onClose={() => {
                                        this.setState({
                                            replyAnchor: null,
                                        })
                                    }}>
                                    <MenuItem onClick={() => {
                                        this.setState({
                                            replyAnchor: null,
                                            replyAll: false,
                                            showReply: true,
                                        })
                                    }}>Reply</MenuItem>
                                    <MenuItem onClick={() => {
                                        this.setState({
                                            replyAnchor: null,
                                            replyAll: true,
                                            showReply: true,
                                        })
                                    }}>Reply All</MenuItem>
                                </Menu>
                            }
                        </Grid>
                    }
                    {
                        this.state.showReply && <Grid item xs={12}>
                            <CCReply key={"reply" + this.props.objectId}
                                     user={this.props.user} authKey={this.props.authKey}
                                     objectId={this.props.objectId} object={this.props.object}
                                     email={this.props.email} refresh={this.props.refresh}
                                     emlKey={this.props.emlKey} replyAll={this.state.replyAll}
                                     close={this.closeReply}/>
                        </Grid>
                    }
                </Grid>
            </div>
        </Paper>
    }
}

export default withStyles(useStyles)(CCEmail)