import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {Button, CircularProgress, Grid, Paper} from "@material-ui/core";
import {renderPaper, renderTo} from "./to";
import clsx from "clsx";
import DOMPurify from "dompurify";
import {Editor} from "react-draft-wysiwyg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import {EditorState} from "draft-js";
import {stateToHTML} from "draft-js-export-html";
import {linkify} from "./apireply";
import {requestReply} from "./api";
import CCAttachment from "./emails/attachment";
import {Alert, AlertTitle} from "@material-ui/lab";
import {Delete} from "@material-ui/icons";

class CCReply extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            replyAll: this.props.replyAll,
            replyExpand: false,
            replying: false,
            repliedHtml: null,
            editorState: EditorState.createEmpty(),
            editorRef: null,
            files: [],
            alertTitle: null,
            alertBody: null,
        };
        this.sendReply = this.sendReply.bind(this);
        this.removeFile = this.removeFile.bind(this);
    }

    getReplyId() {
        return "reply-" + this.props.objectId;
    }

    componentDidMount() {
        this.setState({
            loading: false,
            editorState:  EditorState.moveFocusToEnd(this.state.editorState), // EditorState imported from draft-js
        }, () => {
            const element = document.getElementById(this.getReplyId());
            element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center',
            });
        });
    }

    focus() {
        if (this.state.editorRef !== null) {
            this.state.editorRef.focusEditor();
        }
    }

    isTooLarge() {
        let size = 0;
        this.state.files.map(file => {
            size += file.size;
        })
        return size >= (25 * 1024 * 1024);
    }


    sendReply() {
        if (this.isTooLarge()) {
            this.setState({
                alertTitle: "Message Too Large",
                alertBody: "Please remove some attachments and/or content to decrease the message size!"
            });
            return;
        }

        this.setState({
            replying: true,
            alertTitle: null,
            alertBody: null,
        }, () => {
            let html = DOMPurify.sanitize(stateToHTML(this.state.editorState.getCurrentContent()));
            let to = this.generateTo()
            linkify(this.props.user.email, this.props.authKey,
                to, this.props.user.email,
                this.props.object.metadata.subject, html,
                this.props.objectId, this.props.emlKey, this.state.files,
                (obj_id, obj_key) => {
                    if (obj_id !== null && obj_key !== null) {
                        requestReply(this.props.user.email, this.props.authKey, this.props.objectId, obj_id, obj_key, this.state.replyAll, json => {
                            if (json.hasOwnProperty("status") && json.status === 200) {
                                this.props.refresh(1);
                            } else {
                                this.setState({
                                    alertTitle: "Error",
                                    alertBody: "Failed to send message.",
                                    replying: false,
                                })
                            }
                        });
                    } else {
                        this.setState({
                            alertTitle: "Secure Error",
                            alertBody: "Failed to send message.",
                            replying: false,
                        })
                    }
                })
        })
    }

    resetAlert() {
        if (this.state.alertTitle !== null || this.state.alertBody !== null) {
            this.setState({
                alertTitle: null,
                alertBody: null,
            })
        }
    }

    addFiles(files) {
        let ret = []
        for (let i = 0; i < files.length; i++) {
            ret.push(files[i]);
        }
        for (let i = 0; i < this.state.files.length; i++) {
            ret.push(this.state.files[i]);
        }
        this.setState({
            files: ret,
        })
    }

    removeFile(file) {
        let ret = []
        for (let i = 0; i < this.state.files.length; i++) {
            let curr = this.state.files[i];
            if (curr !== file) {
                ret.push(curr);
            }
        }
        this.setState({
            files: ret,
        }, () => {
            if (!this.isTooLarge()) {
                this.setState({
                    alertTitle: null,
                    alertBody: null,
                })
            }
        })
    }

    renderAttachments(classes) {
        return <Grid item xs={12}>
            <div className={classes.rowFull} style={{flexWrap: "wrap"}}>
                {
                    this.state.files.map(item => {
                        return <CCAttachment file={item} view={this.viewAttachment} remove={this.removeFile}/>;
                    })
                }
            </div>
        </Grid>;
    }

    generateTo() {
        let to = [];
        let from = this.props.email.from.value;
        if (this.state.replyAll) {
            let i;
            let addresses = this.props.email.to.value;
            let self = this.props.user.email;
            for (i = 0; i < addresses.length; i++) {
                let item = addresses[i];
                if (!to.includes(item.address)) {
                    to.push(item.address);
                }
            }
            to = to.filter(item => item !== self);
            if (from.length > 0) {
                let add = from[0];
                if (!to.includes(add.address)) {
                    to.push(add.address);
                }
            }
        } else {
            if (from.length > 0) {
                let add = from[0];
                if (!to.includes(add.address)) {
                    to.push(add.address);
                }
            }
        }
        return to;
    }

    render() {
        const {classes} = this.props;

        let html = this.state.repliedHtml;

        if (this.state.replying) {
            return renderPaper(classes, <div className={classes.row}>
                <CircularProgress style={{marginRight: "20px"}}/>
                <span className={classes.paperFont}>Sending</span>
            </div>, false)
        }

        let to = this.generateTo();
        let toHtml = renderTo(classes, to, this.props.user.email, this.state.replyExpand, () => {
            this.setState({replyExpand: true})
        });
        return <Paper id={this.getReplyId()} style={{overflow: "hidden"}}>
            <div className={classes.paperBackground}>
                <div className={clsx(classes.paperHeader, classes.row)}>
                    {html && <span>Secure Reply Sent</span>}
                    {html === null && <span>Secure Reply</span>}
                    {
                        html === null && <Delete className={classes.right} style={{cursor: "pointer"}} onClick={() => {
                            this.props.close();
                        }}/>
                    }
                </div>
            </div>
            <div className={clsx(classes.paperNarrowTop)}
                 style={{color: "#000", background: "#fff"}}>
                {
                    this.state.alertTitle !== null && this.state.alertBody !== null &&
                    <Grid item xs={12} style={{marginBottom: "20px"}}>
                        <Alert severity="error">
                            <AlertTitle>{this.state.alertTitle}</AlertTitle>
                            {this.state.alertBody}
                        </Alert>
                    </Grid>
                }
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <span className={classes.paperFont}>
                            To: {toHtml}
                        </span>
                    </Grid>
                    <Grid item xs={12}>
                        {
                            html && <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(html)}}/>
                        }
                        {
                            !html &&
                            <Editor
                                editorState={this.state.editorState}
                                onEditorStateChange={state => {
                                    this.setState({
                                        editorState: state
                                    })
                                }}
                                toolbarClassName={classes.hideSm}
                                toolbar={{
                                    options: ['inline', 'list', 'textAlign', 'link', 'history'],
                                    inline: {
                                        options: ['bold', 'italic', 'underline'],
                                    },
                                    list: {
                                        options: ['ordered'],
                                    },
                                    textAlign: {
                                        options: ['left', 'center', 'right'],
                                    }
                                }}
                                placeholder={"Message..."}
                            />
                        }
                    </Grid>
                    {
                        this.state.files.length > 0 && this.renderAttachments(classes)
                    }
                    {
                        !html &&
                        <Grid item xs={12} className={classes.row}>
                            <Button className={clsx(classes.button, classes.fixedWidth)} variant="contained"
                                    onClick={() => {
                                        this.sendReply()
                                    }}>
                                <FontAwesomeIcon icon={faPaperPlane}/>&nbsp;&nbsp;Secure Send
                            </Button>
                            <Button className={clsx(classes.button, classes.fixedWidth, classes.right)} variant="contained"
                                    onClick={() => {
                                        this.upload.click();
                                    }}>
                                <FontAwesomeIcon icon={faLink}/>&nbsp;&nbsp;Attach
                            </Button>
                            <input id="attach"
                                   type="file"
                                   multiple
                                   ref={(ref) => this.upload = ref}
                                   style={{display: 'none'}}
                                   onChange={e => {
                                       this.addFiles(e.target.files);
                                       this.upload.value = ''
                                   }
                                   }
                            />
                        </Grid>
                    }
                </Grid>
            </div>
        </Paper>
    }
}

export default withStyles(useStyles)(CCReply)