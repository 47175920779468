import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "../styles";
import {CircularProgress, Grid} from "@material-ui/core";
import {getObject} from "../api";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {decryptAccessKey, decryptEmailWithDecryptedKey} from "../apireply";
import CCEmail from "./email";
import {renderPaper} from "../to";

class CCMessage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            loadingStatus: null,
            object: null,
            email: null,
            unauthorized: false,
            empty: false,
            objectId: null,
            objectKey: null,
            emlKey: null,
            emails: [],
        };
    }

    componentDidMount() {
        let objId = this.props.objectId;
        let emlKey = this.props.emlKey;

        if (objId === null || emlKey === null) {
            this.setState({
                empty: true,
            })
            return;
        }

        this.setState({objectId: objId, emlKey: emlKey, loadingStatus: "Decrypting"});

        this.props.fetchAccessKey(objId, json => {
            if (json !== null) {
                this.setState({
                    key: json.key
                }, () => {
                    if (this.props.object !== null && this.props.object !== undefined && this.props.object.hasOwnProperty('data')) {
                        let metadata = {}
                        let object = {}
                        for (const [key, value] of Object.entries(this.props.object)) {
                            if (key === 'data' || key === 'files') {
                                object[key] = value;
                            } else {
                                metadata[key] = value;
                            }
                        }
                        object['obj_id'] = this.props.objectId;
                        object['metadata'] = metadata;
                        this.updateWithObject(object);
                    } else {
                        getObject(this.props.user.email, this.props.authKey, objId, json => {
                            this.updateWithObject(json);
                        })
                    }
                })
            } else {
                this.setState({
                    key: '',
                    loading: false,
                    loadingStatus: null,
                    unauthorized: true,
                })
            }
        });
    }

    updateWithObject(json) {
        if (json.hasOwnProperty("status") && json.status === 401) {
            this.setState({
                unauthorized: true,
                loading: false,
            });
        } else {
            decryptAccessKey(this.state.key, this.state.emlKey, accessKey => {
                this.setState({accessKey: accessKey}, () => {
                    decryptEmailWithDecryptedKey(json.data, accessKey, email => {
                        this.updateWithEmail(json, email);
                    });
                });
            });
        }
    }

    updateWithEmail(json, email) {
        if (email !== null) {
            this.setState({
                object: json,
                email: email,
                loading: false,
                loadingStatus: null,
            })
        } else {
            this.setState({
                unauthorized: true,
                loading: false,
                loadingStatus: null,
            })
        }
    }

    render() {
        let {classes} = this.props;

        if (this.props.classes !== undefined) {
            classes = this.props.classes;
        }

        if (this.state.empty || this.state.unauthorized) {
            return renderPaper(classes, <div className={classes.row}>
                {
                    this.state.empty && <span className={classes.paperFont}>No email selected.</span>
                }
                {
                    this.state.unauthorized &&
                    <span className={classes.paperFont}>You do not have access to this content or this content has expired.</span>
                }
            </div>)
        }

        if (this.state.loading || this.state.email === null) {
            return renderPaper(classes, <div className={classes.row}>
                <CircularProgress style={{marginRight: "20px"}}/>
                {
                    this.state.loadingStatus && <span className={classes.paperFont}>{this.state.loadingStatus}</span>
                }
            </div>)
        }

        let object = this.state.object;
        let email = this.state.email;

        let ret = []
        ret.push(<Grid item xs={12}><CCEmail key={"email_" + this.props.objectId}
                                             user={this.props.user} authKey={this.props.authKey} object={object} email={email}
                                             objectId={this.props.objectId} emlKey={this.state.emlKey} refresh={this.props.refresh}
                                             reply={this.state.showReply} showReply={this.showReply} fetchAccessKey={this.props.fetchAccessKey}/></Grid>)
        return ret
    }
}

export default withStyles(useStyles)(CCMessage);