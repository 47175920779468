import logo from "../assets/canary-logo-fs8.png";
import "../styles/common.css"
import React from "react";
import {Toolbar, Typography} from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        color: theme.palette.text.primary,
        background: theme.palette.background.default,
        boxShadow: "none",
        borderBottom: "solid 1px " + theme.palette.divider,
    },
    appBarRight: {
        marginLeft: "auto"
    },
    appBarTitle: {
        color: "inherit",
        [theme.breakpoints.down('sm')]: {
            flexGrow: 1, textAlign: "center"
        }
    },
    appBarItem: {
        width: "40px",
        height: "40px",
        marginLeft: theme.spacing(0),
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('md')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(2),
        }
    },
    hideSm: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    showSm: {
        display: "flex",
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    }
}));

function CCAppToolbar(props) {
    const classes = useStyles();
    return <Toolbar>
        {
            props.headerLeft && false && props.headerLeft
        }
        {
            props.orgName && <Typography className={classes.appBarTitle}>
                {
                    props.orgName
                }
            </Typography>
        }
        <img src={logo} className={clsx(classes.appBarItem, classes.hideSm)} alt=""/>
        <img onClick={props.iconClick} style={{cursor: "pointer"}} src={logo} className={clsx(classes.appBarItem, classes.showSm)} alt=""/>
        <Typography className={clsx(classes.appBarTitle)} style={{cursor: "pointer"}} onClick={() => {
            window.open("https://canarymail.io")
        }
        }>
            <b>Secured via Canary</b>
        </Typography>
        <div className={classes.appBarRight}>
            {
                props.headerRight && props.headerRight
            }
            {
                !props.headerRight && <div className={classes.appBarItem}/>
            }
        </div>
    </Toolbar>
}

export default function CCAppBar(props) {
    const classes = useStyles();

    return <AppBar position="fixed" className={classes.appBar}>
        <CCAppToolbar iconClick={props.iconClick} orgName={props.orgName} headerLeft={props.headerLeft} headerRight={props.headerRight}/>
    </AppBar>;
}