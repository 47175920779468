import {WebSocketContext} from "./socket";
import {useContext} from "react";
import {AuthContext} from "./auth";

function withWebSocket(Component) {
    return function WrappedComponent(props) {
        const ws = useContext(WebSocketContext)
        return <Component {...props} ws={ws} />;
    }
}

function withAuth(Component) {
    return function WrappedComponent(props) {
        const auth = useContext(AuthContext)
        return <Component {...props} auth={auth} />;
    }
}

export { withWebSocket, withAuth }