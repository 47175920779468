import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./../styles";
import {Button, CircularProgress, Container, Grid, TextField, Typography} from "@material-ui/core";
import img from "../../../assets/main-layout-fs8.png";
import clsx from "clsx";
import logo from "../../../assets/canary-logo-fs8.png";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGoogle} from '@fortawesome/free-brands-svg-icons'
import CCOrDivider from "./helpers";

class CCSignInEmail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            user: "",
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        return <Grid container direction="column"
                     justify="center"
                     alignItems="center" spacing={3}>
            <Grid item><img className={clsx(classes.logo)} src={logo} alt=""/></Grid>
            <Grid item><Typography variant="h1" style={{textAlign: "center"}}><b>Confirm your email</b></Typography></Grid>
            <Grid item><Typography variant="subtitle1" style={{textAlign: "center"}}>Verify your identity & access the message:</Typography></Grid>
            <Grid item>
                <Button className={clsx(classes.button, classes.buttonLogin)} variant="contained"
                        onClick={this.props.signInWithGoogle}>
                    <FontAwesomeIcon icon={faGoogle} />&nbsp;Continue with Google
                </Button>
            </Grid>
            <CCOrDivider/>
            <Grid item className={"column"}>
                <TextField className={clsx(classes.buttonLogin, classes.textField)} onChange={(e) => {
                    this.setState({user: e.target.value})
                }}
                           required
                           size="small"
                           id="outlined-required"
                           label="Email"
                           margin="none"
                           value={this.state.user}
                           variant="outlined"/>
            </Grid>
            <Grid item>
                <Button className={clsx(classes.button, classes.buttonLogin)} variant="contained"
                        onClick={() => this.props.signInWithEmail(this.state.user)}>
                    Get Magic Link
                </Button>
            </Grid>
        </Grid>;
    }
}

export default withStyles(useStyles)(CCSignInEmail)