const config = {
    apiKey: "AIzaSyDNBk0DByxoDTx_9ZDA3zFWY9UyHyWii7o",
    authDomain: "secure.canarymail.io",
    projectId: "canary-links-ffe67",
    storageBucket: "canary-links-ffe67.appspot.com",
    messagingSenderId: "3754335591",
    appId: "1:3754335591:web:9cbd86f40beab86cc823c2",
    measurementId: "G-ZWE51C6S5E"
};

export default config;