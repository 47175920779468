import {v4 as uuidv4} from 'uuid';
import CCCryptor from "./crypto";
import {uploadReply} from "./api";
import {Readable, ReadableOptions} from "stream";
import {checkNotNull} from "./null";

class MultiStream extends Readable {
    _object: any;

    constructor(object: any, options: ReadableOptions) {
        super(object instanceof Buffer || typeof object === "string" ? options : {objectMode: true});
        this._object = object;
    }

    _read = () => {
        this.push(this._object);
        this._object = null;
    };
}

function generateKey() {
    return uuidv4();
}

function decrypt(b64str, key, callback) { // -> buffer
    CCCryptor.Decrypt(b64str, key, callback)
}

function encrypt(buffer: Buffer, key) { // -> b64 str
    return CCCryptor.Encrypt(buffer, key);
}

function convertB64ToUrlSafe(b64) {
    let clean = b64.slice();
    clean = clean.replaceAll('/', '_')
    clean = clean.replaceAll('+', '-')
    clean = clean.replaceAll('=', '.')
    return clean
}

function convertUrlSafeToB64(b64) {
    let clean = b64.slice();
    clean = clean.replaceAll('_', '/');
    clean = clean.replaceAll('-', '+');
    clean = clean.replaceAll('.', '=');
    return clean;
}

function readFiles(files, idx, loaded, callback) {
    if (idx === files.length) {
        callback(loaded);
        return;
    }
    let reader = new FileReader();
    let file = files[idx];
    reader.onloadend = (e => {
        let buffer = Buffer.from(new Uint8Array(e.target.result));
        let att = {name: file.name, mimeType: file.type, data: buffer};
        loaded.push(att);
        readFiles(files, idx + 1, loaded, callback);
    })
    reader.readAsArrayBuffer(file);
}

function linkify(user, authKey, to, from, subject, html, parent_id, parent_key, files, callback) {
    let adds = []
    for (let i = 0; i < to.length; i++) {
        adds.push({email: to[i]})
    }
    let data = {
        from: from,
        to: adds,
        subject: subject,
        html: html
    }

    readFiles(files, 0, [], atts => {
        const emlformat = require('eml-format');
        emlformat.build(data, (error, eml) => {
            if (error !== null) {
                callback(null);
            } else {
                upload(user, authKey, to, subject, Buffer.from(eml, 'utf-8'),
                    atts, parent_id, parent_key, (obj_id, obj_key) => {
                    if (obj_id === null || obj_key === null) {
                        callback(null, null);
                        return;
                    }
                    callback(obj_id, obj_key);
                })
            }
        })
    })
}

function generateFiles(atts, to, emlKey) {
    let ret = [];
    for (let i = 0; i < atts.length; i++) {
        let att = atts[i];
        let filename = att.name;
        let mimeType = att.mimeType;
        let data = att.data;
        let size = data.length;
        let info = {}
        info['to'] = to;
        if (checkNotNull(filename)) {
            info['filename'] = filename;
        }
        if (checkNotNull(size)) {
            info['size'] = size;
        }
        if (checkNotNull(mimeType)) {
            info['content_type'] = mimeType;
        }

        let fileDict = {}
        fileDict['enc_info'] = info;

        let accessKey = generateKey();
        fileDict['enc_object'] = encrypt(data, accessKey);

        let accessKeyBuffer = Buffer.from(accessKey, 'utf-8');
        let accessKeyB64 = encrypt(accessKeyBuffer, emlKey);
        accessKeyB64 = convertB64ToUrlSafe(accessKeyB64);
        fileDict["enc_access_key"] = accessKeyB64
        ret.push(fileDict);
    }
    return ret;
}

function upload(user, authKey, to, subject, eml: Buffer, atts, parent_id, parent_key, callback) {
    let accessKey = generateKey();
    let emlKey = parent_key !== null && parent_id !== null ? parent_key : generateKey();

    let post = {};
    post['user'] = user;
    post['auth_key'] = authKey;
    post['enc_object'] = encrypt(eml, accessKey);

    let accessKeyBuffer = Buffer.from(accessKey, 'utf-8');
    let accessKeyB64 = encrypt(accessKeyBuffer, emlKey);
    accessKeyB64 = convertB64ToUrlSafe(accessKeyB64);

    let emlKeyB64 = Buffer.from(emlKey, 'utf-8').toString('base64');
    emlKeyB64 = convertB64ToUrlSafe(emlKeyB64);

    let info = {}
    info["to"] = to
    info["expiry"] = 0
    if (subject !== null) {
        info["subject"] = subject
    }
    post["enc_info"] = info
    post["enc_access_key"] = accessKeyB64
    if (parent_id !== null) {
        post["parent_id"] = parent_id;
    }
    if (checkNotNull(atts) && atts.length > 0) {
        post['files'] = generateFiles(atts, to, emlKey);
    }

    uploadReply(post, json => {
        if (json === null || !json.hasOwnProperty("response")) {
            console.log('Reply failed: ', json);
            callback(null, null);
        } else {
            const objId = json["response"];
            callback(objId, emlKeyB64);
        }
    })
}

// pw -> data bytes
// data bytes -> encrypted
// encrypted -> b64
// b64 -> url safe b64

function decryptEmlKey(objectKey) {
    let emlKey = convertUrlSafeToB64(objectKey);
    emlKey = Buffer.from(emlKey, 'base64').toString('utf-8');
    return emlKey;
}

function decryptAccessKey(accessKey, emlKey, callback) {
    let accessKeyData = convertUrlSafeToB64(accessKey)
    decrypt(accessKeyData, emlKey, accessKey => {
        callback(accessKey.toString('utf-8'));
    })
}

function decryptEmailWithDecryptedKey(data, emlKey, callback) {
    // need to decrypt buffer
    decrypt(data, emlKey, buffer => {
        if (buffer === null) {
            callback(null);
            return;
        }
        const EmlParser = require('eml-parser');
        new EmlParser(new MultiStream(buffer)).parseEml().then(eml => {
            callback(eml);
        })
    });
}

function decryptFileWithDecryptedKey(data, emlKey, callback) {
    decrypt(data, emlKey, buffer => {
        if (buffer === null) {
            callback(null);
            return;
        }
        callback(buffer);
    });
}

export {
    linkify, encrypt, decrypt, decryptEmailWithDecryptedKey,
    decryptAccessKey, decryptEmlKey, decryptFileWithDecryptedKey
}