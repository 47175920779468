import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "../styles";
import {Button, CircularProgress, Container, Grid, Paper} from "@material-ui/core";
import clsx from "clsx";
import {ChevronLeft} from "@material-ui/icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDownload} from "@fortawesome/free-solid-svg-icons";
import CCPDF from "./pdf";
import DOMPurify from "dompurify";
import CCSpreadsheet from "./excel";

class CCViewer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            el: null,
            loading: true,
            page: 1,
            numPages: null,
        };
        this.download = this.download.bind(this);
    }

    componentDidMount() {
        this.setState({
            file: {data: this.props.attachment.content},
            loading: false
        }, () => {
            if (this.isDocX()) {
                this.renderDocX(el => {
                    this.setState({el: el});
                })
            }
        })
    }

    isDocX() {
        let type = this.props.attachment.contentType;
        return type === "application/msword" || type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
    }

    isExcel() {
        let type = this.props.attachment.contentType;
        return type === "application/vnd.ms-excel" ||
            type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    }

    getBlobUrl() {
        let blob = new Blob([this.props.attachment.content], {type: this.props.attachment.contentType});
        let url = window.URL.createObjectURL(blob);
        return url;
    }

    renderDocX(callback) {
        const mammoth = require('mammoth');
        mammoth.convertToHtml({
            arrayBuffer: this.props.attachment.content
        }).then(result => {
            let ret = <div style={{background: "#fff", color: "#000", padding: "20px",}}>
                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(result.value)}}/>
            </div>;
            callback(ret);
        });
    }

    download() {
        let attachment = this.props.attachment;
        let blob = new Blob([attachment.content], {type: attachment.contentType});
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = attachment.filename;
        link.click();
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        let att = this.state.el;
        let type = this.props.attachment.contentType;
        if (att === null && type !== null) {
            if (type.startsWith('image')) {
                att = <img src={this.getBlobUrl()} alt={""}/>
            } else if (type.startsWith("video")) {
                att = <video autoPlay muted loop><source src = {this.getBlobUrl()}/></video>
            } else if (type.endsWith('pdf')) {
                att = <CCPDF file={this.state.file}/>;
            } else if (type === "application/msword" ||
                type === "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
                att = <span>Generating Preview...</span>
            } else if (this.isExcel()) {
                att = <CCSpreadsheet attachment={this.props.attachment}/>
            }
        }

        return <Paper className={classes.paperLight} style={{overflow: "hidden"}}>
            <div className={classes.paperBackground}>
                <div className={clsx(classes.paperHeader, classes.rowFull)} style={{justifyContent: "center"}}>
                    <Button onClick={() => this.props.back()}><ChevronLeft/></Button>
                    <span style={{margin: "auto"}}>
                        {this.props.attachment.filename}
                    </span>
                    <div className={clsx(classes.row)} style={{marginRight: "0px"}}>
                        <Button className={clsx(classes.button, classes.fixedWidth)}
                                variant="contained"
                                onClick={() => {
                                    this.download();
                                }}>
                            <FontAwesomeIcon icon={faDownload}/>&nbsp;&nbsp;Download
                        </Button>
                    </div>
                </div>
            </div>
            <div className={classes.paperNarrow}>
            <Grid container spacing={3} className={classes.paper}>
                <Grid item xs={12} className={clsx(classes.column, classes.center)}>
                    {
                        att != null && att
                    }
                    {
                        att === null && <span className={classes.paperFont}>Preview not available</span>
                    }
                </Grid>
            </Grid>
            </div>
        </Paper>;
    }
}

export default withStyles(useStyles)(CCViewer)