import {alpha} from "@material-ui/core";

const drawerWidth = 300;

const useStyles = theme => ({
    dashboardRoot: {
        display: 'flex',
    },
    orgRoot: {
        display: 'flex',
        flexDirection: "column"
    },
    root: {
        width: "100%",
        maxWidth: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        marginTop: theme.spacing(3),
    },
    parent: {
        marginTop: "80px",
        width: "100vw",
        height: "auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    rootFull: {
        width: "100%",
        maxWidth: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
    },
    grid: {
        outline: 'none',
        '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
    },
    column: {
        display: "flex",
        flexDirection: "column",
        width: "100%"
    },
    center: {
        alignItems: "center",
    },
    rowFull: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        width: "100%"
    },
    row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },
    rowSm: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
        }
    },
    rowEnd: {
        alignItems: "end",
        [theme.breakpoints.down('sm')]: {
            alignItems: "start",
        }
    },
    code: {
        background: "#333333",
        padding: "2px",
        fontSize: "12px",
        lineHeight: "2em",
    },
    paper: {
        padding: theme.spacing(3),
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    paperNarrow: {
        padding: theme.spacing(2),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    paperNarrowTop: {
        padding: theme.spacing(3),
        paddingTop: theme.spacing(2),
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
            paddingTop: theme.spacing(1),
        },
    },
    paperHeader: {
        fontSize: theme.typography.pxToRem(14.5),
        fontWeight: 600,
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: "100%",
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    paperFont: {
        fontSize: theme.typography.pxToRem(14.5),
        fontWeight: 600,
    },
    paperBackground: {
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.primaryDark[800] : theme.palette.primary[50],
        color: theme.palette.text.primary,
        width: "100%",
    },
    paperLight: {
        backgroundColor:
            theme.palette.mode === 'dark' ? theme.palette.primaryDark[800] : '',
        borderColor:
            theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : '',
        border: 'solid 1px',
    },
    paperGrid: {
        borderColor:
            theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : '',
        "& .MuiDataGrid-cell": {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : '',
        },
        "& .MuiDataGrid-columnsContainer": {
            borderColor: theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : '',
        },
        "& .MuiDataGrid-columnSeparator": {
            color: theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : '',
        },
        "& .MuiIconButton-root.Mui-disabled": {
            color: theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : '',
        },
        '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
            outline: 'none',
        },
        "& .MuiIconButton-root": {
            color: theme.palette.primaryDark[400]
        },
        "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
            outline: 'none',
        }
    },
    paperSmall: {
        padding: theme.spacing(3),
        width: "100%",
        maxWidth: "500px",
    },
    paperTabbed: {
        paddingBottom: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        paddingRight: theme.spacing(3),
        width: "100%",
    },
    paperTabs: {
        marginBottom: theme.spacing(3),
    },
    right: {
        marginLeft: "auto"
    },
    rightSm: {
        marginLeft: "auto",
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0px"
        }
    },
    bold: {
        fontWeight: "700"
    },
    normal: {
        fontWeight: "regular"
    },
    button: {
        backgroundColor: "#006efe",
        color: "#fff",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#007fff"
        }
    },
    buttonLogin: {
        width: "250px", height: "44px", fontSize: "1rem"
    },
    buttonRound: {
        width: "36px",
        height: "36px",
        borderRadius: "18px",
        backgroundColor: "#006efe",
        color: "#fff",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#007fff"
        }
    },
    buttonRed: {
        backgroundColor: "#b80f0a",
        color: "#fff",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#c80f0a"
        }
    },
    hideSm: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    card: {
        cursor: "pointer"
    },
    bottomButton: {
        margin: theme.spacing(2)
    },
    bottom: {
        marginBottom: theme.spacing(3),
    },
    marginTop: {
        marginTop: theme.spacing(3),
    },
    marginBottom: {
        marginTop: theme.spacing(3),
    },
    marginRight: {
        marginRight: theme.spacing(3),
    },
    noOverflow: {
        overflow: "hidden"
    },
    floating: {
        position: "fixed",
        right: theme.spacing(6),
        top: theme.spacing(10)
    },
    largeImage: {
        width: "80px",
        height: "80px",
    },
    list: {
        padding: theme.spacing(1),
    },
    li: {
        marginTop: "5px",
        marginBottom: "5px",
        borderRadius: "5px",
        fontSize: theme.typography.pxToRem(14.5),
        fontWeight: 600,
        color: theme.palette.text.primary,
        '&.Mui-selected': {
            // color: theme.palette.primary.main,
            color:
                theme.palette.mode === 'dark' ? theme.palette.primary[200] : theme.palette.primary[500],
            backgroundColor:
                theme.palette.mode === 'dark' ? theme.palette.primaryDark[600] : theme.palette.primary[50],
            fontWeight: 700,
            '&:hover': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity + theme.palette.action.hoverOpacity,
                ),
                // Reset on touch devices, it doesn't add specificity
                '@media (hover: none)': {
                    backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
                },
            },
            '&.Mui-focusVisible': {
                backgroundColor: alpha(
                    theme.palette.primary.main,
                    theme.palette.action.selectedOpacity + theme.palette.action.focusOpacity,
                ),
            },
        },
    },
    item0: {
        paddingLeft: theme.spacing(3),
    },
    item1: {
        paddingLeft: theme.spacing(6),
    },
    itemIcon: {
        '& svg': {fontSize: theme.typography.pxToRem(14)},
        color: theme.palette.primary.main,
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        marginRight: "12px",
        padding: "4px",
        borderRadius: '5px',
        backgroundColor: theme.palette.mode === 'dark'
            ? theme.palette.primaryDark[700]
            : theme.palette.primary[50],
    },
    itemOpenIcon: {
        color: theme.palette.primary.main,
        fontSize: '1rem',
        float: 'right',
        marginLeft: "auto",
    },
    capitalize: {
        textTransform: "capitalize"
    },
    drawer: {
        [theme.breakpoints.up('md')]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    largeNavDrawer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    profile: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        marginRight: "10px",
        marginLeft: "20px",
    },
    // necessary for content to be below app bar
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(3)
        },
    },
    appBarRight: {
        marginLeft: "auto"
    },
    appBarTitle: {
        color: "inherit"
    },
    appBarItem: {
        width: "40px",
        height: "40px",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.down('xs')]: {
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
        }
    },
    activeTitle: {
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
        }
    },
    activeSubtitle: {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(3),
        },
        marginRight: theme.spacing(3),
    },
    profileButton: {
        textTransform: 'none',
    },
    profileImage: {
        width: "30px",
        height: "30px",
        borderRadius: "15px",
        marginRight: "16px",
        [theme.breakpoints.down('sm')]: {
            marginRight: "0px",
        },
    },
    chip: {
        margin: "4px",
    },
    url: {
        color: "#006efe",
        cursor: "pointer"
    },
    reply: {
        marginTop: theme.spacing(2),
    },
    hide: {
        display: "none",
    },
    attachment: {
        borderColor:
            theme.palette.mode === 'dark' ? theme.palette.primaryDark[400] : '',
        border: 'solid 2px',
        borderRadius: "12px",
        padding: "4px",
        paddingLeft: "12px",
        paddingRight: "12px",
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: "200px",
        marginBottom: "12px",
        cursor: "pointer",
        position: "relative",
        "&:hover": {
            backgroundColor: theme.palette.primaryDark[100]
        },
        "&:hover $attachmentShowOnHover": {
            display: "flex",
            opacity: 1,
        },
    },
    attachmentShowOnHover: {
        opacity: 0,
        backgroundColor: "#fffe",
        display: "none",
        position: "absolute",
        top: 0,
        left: 0,
        height: "100%",
        width: "100%",
        transition: "opacity ease-in-out 0.2s",
    },
    attachmentButton: {
        width: "50%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 600,
        transition: "background ease-in-out 0.2s",
        "&:hover, &:focus": {
            backgroundColor: "#e6e6e6"
        }
    },
    attachmentIcon: {
        width: "20px",
        height: "20px",
        marginRight: "8px",
    },
    attachmentTitle: {
        fontSize: theme.typography.pxToRem(12),
        fontWeight: 600,
        whiteSpace: "nowrap",
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    attachmentSubtitle: {
        fontSize: theme.typography.pxToRem(9),
        fontWeight: 600,
        whiteSpace: "nowrap",
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    pdf: {
        maxWidth: "100%",
        height: "800px",
        position: "relative",
        "&:hover $pageControls": {
            opacity: 1,
        }
    },
    pageControls: {
        color: "black",
        opacity: 0,
        position: "absolute",
        bottom: "5%",
        left: "50%",
        background: "white",
        transform: "translateX(-50%)",
        transition: "opacity ease-in-out 0.2s",
        borderRadius: "4px",
        boxShadow: "0 30px 40px 0 rgb(16 36 94 / 20%)",
    },
    pdfSpan: {
        padding: "0 .5em",
    },
    pdfButton: {
        width: "44px",
        height: "44px",
        background: "white",
        border: 0,
        font: "inherit",
        fontSize: ".8em",
        borderRadius: "4px",
        "&:enabled": {
            "&:hover": {
                cursor: "pointer"
            },
            "&:hover, &:focus": {
                backgroundColor: "#e6e6e6"
            }
        },
        "&:first-child": {
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
        },
        "&:last-child": {
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,
        }
    },
    footer: {
        display: "flex",
        flexDirection: "row",
        color: theme.palette.primaryDark[400],
        [theme.breakpoints.down('sm')]: {
            flexDirection: "column",
            width: "100%",
            textAlign: "center",
        }
    },
    footerItem: {
        cursor: "pointer",
        transition: "color .2s",
        marginLeft: "60px",
        "&:hover": {
            color: theme.palette.primary[400]
        },
        [theme.breakpoints.down('sm')]: {
            marginLeft: "0px",
            marginTop: "30px",
            width: "100%"
        }
    },
    remainingItem: {
        color: theme.palette.primaryDark[400],
        [theme.breakpoints.down('sm')]: {
            marginTop: "20px",
            textAlign: "center",
        }
    },
    logo: {
        width: "80px",
        height: "80px",
    },
    textField: {
        '& label.Mui-focused': {
            color: 'white',
        },
        '& label.Mui-disabled': {
            color: theme.palette.text.secondary,
        },
        '& .MuiInputBase-root.Mui-disabled': {
            color: theme.palette.primaryDark[400],
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : '',
            },
            '&:hover fieldset': {
                borderColor: theme.palette.mode === 'dark' ? theme.palette.primaryDark[500] : '',
            },
            '&.Mui-focused fieldset': {
                borderColor: theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : '',
            },
            '&.Mui-disabled fieldset': {
                borderColor: theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : '',
            },
        },
        '& .MuiSelect-icon': {
            color: theme.palette.mode === 'dark' ? theme.palette.primaryDark[700] : '',
        }
    },
});

const loginStyles = theme => ({
    root: {
        display: "flex",
        height: "100vh",
        width: "50vw",
    },
    logo: {
        width: "80px",
        height: "80px",
    },
    column: {
        height: "100vh",
        width: "50vw",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('sm')]: {
            width: "100vw",
            height: "100vh",
        },
    },
    solid: {
        background: "#000",
        color: theme.palette.text.primary,
        zIndex: 10,
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 1)"
    },
    gradient: {
        background: "linear-gradient(-45deg, rgb(170, 34, 255), rgb(0, 238, 255))",
        [theme.breakpoints.down('sm')]: {
            display: "none",
        },
    },
    buttonOn: {
        backgroundColor: "#006efe",
        color: "#fff",
        height: "40px",
        borderRadius: "10px",
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#007fff"
        }
    },
    image: {
        maxHeight: "100%",
    },
    end: {
        alignItems: "flex-end"
    },
    item: {
        marginBottom: theme.spacing(2),
    },
    url: {
        color: "#006efe",
        cursor: "pointer"
    },
    fixedWidth: {
        width: "300px",
    },
    divider: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
    },
    dividerPiece: {
        flexGrow: 1,
        background: theme.palette.divider,
        height: "1px",
    }
});

export {useStyles, loginStyles};