import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import {withStyles} from '@material-ui/core/styles';
import CCAppBar from "./appbar";
import {Box, Menu, MenuItem} from "@material-ui/core";
import {Description, ExpandMore, KeyboardArrowRightRounded, Mail, MenuBook, MenuRounded} from "@material-ui/icons";
import {withWebSocket} from "./pages/context";
import {useStyles} from "./pages/styles";
import {getInfoText, getInfoTitle} from "./pages/info";
import CCObjects from "./pages/objects";
import clsx from "clsx";
import CCThread from "./pages/emails/thread";
import CCFooter from "./pages/footer";

function sendEmail(props) {
    props.user.sendEmailVerification().then()
}

class CCDashboard extends React.Component<{}> {
    constructor() {
        super();
        this.state = {
            mobileOpen: false,
            activeTab: "Reader",
            menuAnchor: null,
            showOrganizationMenu: true,
            showEncryptionMenu: true,
            showConfigurationMenu: true,
            loading: true,
            objId: null,
            objKey: null,
        }
        this.setMobileOpen = this.setMobileOpen.bind(this)
        this.setActiveTab = this.setActiveTab.bind(this)
    }

    componentDidMount() {
        let objId = this.props.objId;
        let objKey = this.props.objKey;
        if (objId !== null && objId !== undefined && objKey !== null && objKey !== undefined) {
            this.setState({
                objId: objId,
                objKey: objKey
            }, () => {
                this.refresh();
            })
        } else {
            this.setState({
                activeTab: "Emails"
            }, () => {
                this.refresh();
            })
        }
    }

    showReader() {
        return this.state.objId !== null && this.state.objKey !== null;
    }

    refresh() {
        this.setState({
            loading: false
        }, () => {

        })
    }

    setMobileOpen(open) {
        this.setState({
            mobileOpen: open
        })
    }

    setActiveTab(tab) {
        this.setState({
            activeTab: tab
        })
    }

    getIcon(name) {
        if (name === 'Reader') {
            return <MenuBook/>
        } else if (name === 'Emails') {
            return <Mail/>
        } else {
            return <Description/>
        }
    }

    getOpenIcon(classes, open) {
        return <KeyboardArrowRightRounded className={classes.itemOpenIcon} style={{
            transform: open && 'rotate(90deg)',
        }}/>
    }

    getListIcon(classes, text) {
        return <Box
            component="span"
            className={classes.itemIcon}>
            {this.getIcon(text)}
        </Box>
    }

    getListItem(classes, text, className, onClick, depth, open, showOpen = false) {
        return <ListItem button
                         className={className && className}
                         key={text}
                         selected={text === this.state.activeTab}
                         onClick={onClick != null ? onClick : () => {
                             this.setActiveTab(text)
                         }}>
            {this.getListIcon(classes, text)}
            <span>{text}</span>
            {showOpen && this.getOpenIcon(classes, open)}
        </ListItem>
    }

    render() {
        const {classes} = this.props;
        const {window} = this.props;

        const profile = <div className={"row"} style={{cursor: "pointer"}}>
            <div className={classes.profile}>
                <div className={clsx(classes.profileButton, classes.row)} onClick={e => this.setState({menuAnchor: e.currentTarget})}>
                    {
                        this.props.user.photoURL &&
                        <img src={this.props.user.photoURL} className={classes.profileImage} alt=""/>
                    }
                    <Typography className={classes.hideSm} style={{marginRight: "12px", color: "inherit"}}>
                        {this.props.user.displayName ? this.props.user.displayName : this.props.user.email}
                    </Typography>
                    <ExpandMore className={classes.hideSm} style={{color: "inherit"}}/>
                </div>
                <Menu
                    id="simple-menu"
                    anchorEl={this.state.menuAnchor}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    keepMounted
                    open={Boolean(this.state.menuAnchor)}
                    onClose={e => {
                        this.setState({menuAnchor: null})
                    }}>
                    <MenuItem onClick={e => {
                        this.props.signOut();
                        this.setState({menuAnchor: null});
                    }}>Sign Out</MenuItem>
                </Menu>
            </div>
        </div>

        const handleDrawerToggle = () => {
            this.setMobileOpen(!this.state.mobileOpen);
        };

        const drawer = (
            <div>
                <div className={classes.toolbar}/>
                <Divider/>
                <List className={classes.list}>
                    {
                        this.showReader() && this.getListItem(classes, "Reader", clsx(classes.li, classes.item1), null, 0, false)
                    }
                    {
                        this.getListItem(classes, "Emails", clsx(classes.li, classes.item1), null, 0, false)
                    }
                    {
                        this.getListItem(classes, "Files", clsx(classes.li, classes.item1), null, 0, false)
                    }
                </List>
            </div>
        );

        const container = window !== undefined ? () => window().document.body : undefined;
        const menuIcon = <div className={classes.row}>
            <IconButton onClick={handleDrawerToggle} className={classes.menuButton}>
                <MenuRounded style={{color: "fff"}}/>
            </IconButton>
        </div>

        const title = getInfoTitle(this.state.activeTab);
        const info = getInfoText(this.state.activeTab);

        return (
            <div className={classes.dashboardRoot}>
                <CssBaseline/>
                <CCAppBar position="fixed" className={classes.appBar}
                          iconClick={handleDrawerToggle}
                          headerLeft={menuIcon} headerRight={profile}
                          orgName={this.state.org_name}/>
                <nav className={classes.drawer} aria-label="mailbox folders">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor="left"
                            open={this.state.mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Drawer
                        className={classes.largeNavDrawer}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        variant="permanent"
                        open>
                        {drawer}
                    </Drawer>
                </nav>

                <main className={classes.content} style={{minHeight: "100vh", display: "flex", flexDirection: "column"}}>
                    <div className={classes.toolbar}/>
                    {
                        title && <Typography className={classes.activeTitle} variant="h1"><b>{this.state.activeTab}</b></Typography>
                    }
                    {
                        info && <Typography className={classes.activeSubtitle} variant="subtitle1">{info}</Typography>
                    }
                    {
                        this.state.activeTab === 'Reader' && this.showReader() &&
                        <CCThread user={this.props.user}
                                  authKey={this.props.authKey}
                                  fetchAccessKey={this.props.fetchAccessKey}
                                  objectId={this.state.objId}
                                  objectKey={this.state.objKey}/>
                    }
                    {
                        this.state.activeTab === 'Emails' &&
                        <CCObjects user={this.props.user} authKey={this.props.authKey} kind={'email'}/>
                    }
                    {
                        this.state.activeTab === 'Files' &&
                        <CCObjects user={this.props.user} authKey={this.props.authKey} kind={'file'}/>
                    }
                    <CCFooter/>
                </main>
            </div>
        );
    }
}

export default withWebSocket(withStyles(useStyles)(CCDashboard))
