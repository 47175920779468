import React, {createContext} from 'react'

// const socketUrl = 'ws://localhost:9300/feed';
const socketUrl = 'wss://api.canarymail.io/feed';

class SocketHandler {
    constructor() {
        this.callbacks = []
        this.unauthorized = false
        this.connected = false
        this.user_email = ""
        this.user_auth_key = ""
        this.socket = null;
        this.modseq = 0;
    }

    setCredentials(user_email, auth_key) {
        this.user_email = user_email
        this.user_auth_key = auth_key
        if (this.socket) {
            this.socket.close();
        } else {
            this.connect()
        }
    }

    sendMessage(json) {
        this.socket.send(JSON.stringify(json))
    }

    receivedMessage(json) {
        if (json.hasOwnProperty("op")) {
            let op = json["op"]
            if (op === 'AUTH') {
                this.authenticate()
                return;
            } else if (op === 'SYNC') {
                this.sync(json)
                return;
            }
        }
        this.callbacks.forEach(callback => {
            callback(json);
        })
    }

    authenticate() {
        this.socket.send(JSON.stringify({email: this.user_email, auth_key: this.user_auth_key}))
    }

    sync(json) {
        if (json.hasOwnProperty("modseq")) {
            let new_modseq = json["modseq"]
            if (this.modseq !== new_modseq) {
                this.callbacks.forEach(callback => {
                    callback(json);
                })
            }
        }
    }

    addCallback(callback) {
        this.callbacks.push(callback)
    }

    removeCallback(callback) {
        this.callbacks = this.callbacks.filter(item => item !== callback)
    }

    connect() {
        if (this.unauthorized) {
            return;
        }
        if (this.user_email == null || this.user_auth_key == null) {
            return;
        }
        this.socket = new WebSocket(socketUrl);
        this.socket.onopen = () => {
            this.connected = true;
            console.log('Socket opened')
        }
        let self = this;
        this.socket.onclose = (e) => {
            this.connected = false;
            console.log('Socket is closed. Reconnect will be attempted in 1 second.', e.reason);
            setTimeout(function() {
                self.connect()
            }, 1000);
        }
        this.socket.onerror = (e) => {
            this.socket.close()
        }
        this.socket.onmessage = e => {
            const message = JSON.parse(e.data);
            this.receivedMessage(message);
        };
    }
}

const socket = new SocketHandler()

const WebSocketContext = createContext(socket)

export { WebSocketContext }

const socketWrapper = ({ children }) => {
    return (
        <WebSocketContext.Provider value={{socket}}>
            {children}
        </WebSocketContext.Provider>
    )
};

export default socketWrapper;