import {withStyles} from "@material-ui/core/styles";
import {Component, createElement} from "react";
import {useStyles} from "../styles";
import {CircularProgress, Container} from "@material-ui/core";
import XLSX from "xlsx";
import {findDOMNode} from "react-dom";
import 'canvas-datagrid'

class CanvasDataGrid extends Component {
    constructor(props) {
        super(props);
    }
    updateAttributes(nextProps) {
        Object.keys(this.props).forEach(key => {
            if (!nextProps || this.props[key] !== nextProps[key]) {
                if (this.grid.attributes[key] !== undefined) {
                    this.grid.attributes[key] = nextProps ? nextProps[key] : this.props[key];
                } else {
                    this.grid[key] = nextProps ? nextProps[key] : this.props[key];
                }
            }
        });
    }
    componentWillReceiveProps(nextProps, nextContext) {
        this.updateAttributes(nextProps);
    }
    shouldComponentUpdate() {
        return false;
    }
    componentWillUnmount() {
        this.grid.dispose();
    }
    componentDidMount() {
        let args = {};
        this.grid = findDOMNode(this);
        this.updateAttributes();
    }
    render() {
        return createElement('canvas-datagrid', {});
    }
}

class CCSpreadsheet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            workbook: null,
            sheet: 0,
        };
    }

    componentDidMount() {
        this.loadFile();
    }

    loadFile() {
        let workbook = XLSX.read(this.props.attachment.content, {type: "array"});

        this.setState({
            loading: false,
            workbook: workbook,
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        let name = this.state.workbook.SheetNames[this.state.sheet];
        let ws = this.state.workbook.Sheets[name];
        let json = XLSX.utils.sheet_to_json(ws, {raw: false, header: 1});

        let L = 0;
        json.forEach(function(r) { if(L < r.length) L = r.length; });
        for(let i = json[0].length; i < L; ++i) {
            json[0][i] = "";
        }

        return <div style={{overflow: "scroll", width: "100%", height: "500px", position: "relative"}}>
            <CanvasDataGrid style={{position: "absolute", left: 0, top: 0}} data={json}/>
        </div>;
    }
}

export default withStyles(useStyles)(CCSpreadsheet);