import {brandingDarkTheme, brandingLightTheme} from "./pages/branding";

function lightTheme() {
    return brandingLightTheme;
}

function darkTheme() {
    return brandingDarkTheme;
}

export {
    lightTheme, darkTheme
}