import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {CircularProgress, Container, Divider} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

class CCFooter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        return <Container className={classes.root} style={{marginTop: "auto"}}>
            <Divider flexItem orientation="horizontal" style={{height: "1px", marginTop: "30px", width: "100%"}}/>
            <div className={classes.footer} style={{width: "100%", marginTop: "30px"}}>
                <Typography variant={"caption"}>© Copyright 2022 Canary Mail</Typography>
                <Typography className={classes.footerItem} style={{marginLeft: "auto"}} variant={"caption"}
                            onClick={() => window.open("https://canarymail.io", "_blank")}>
                    Get Canary
                </Typography>
                <Typography className={classes.footerItem} variant={"caption"}
                            onClick={() => window.open("https://canarymail.io/terms.html", "_blank") }>Terms</Typography>
                <Typography className={classes.footerItem} variant={"caption"}
                            onClick={() => window.open("https://canarymail.io/privacy.html", "_blank")}>Privacy</Typography>
                <Typography className={classes.footerItem} variant={"caption"}
                            onClick={() => window.open("https://help.canarymail.io", "_blank")}>Support</Typography>
            </div>
        </Container>;
    }
}

export default withStyles(useStyles)(CCFooter)