function getInfoText(activeTab) {
    if (activeTab === 'Emails') {
        return "Manage messages sent by you.";
    } else if (activeTab === 'Files') {
        return "Manage files sent by you.";
    }
    return null
}

function getInfoTitle(activeTab) {
    if (activeTab === 'Emails') {
        return "Emails";
    } else if (activeTab === 'Files') {
        return "Files";
    }
    return null;
}

export {
    getInfoTitle, getInfoText
}