// const baseUrl = "http://localhost:9303";
const baseUrl = "https://encryption.canarymail.io"

function postJson(url, data, callback) {
    const requestOptions = {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(data)
    };
    fetch(url, requestOptions)
        .then(response => response.json())
        .then(callback)
}

function portal(email, callback) {
    const url = baseUrl + "/portal";
    postJson(url, {"user": email}, callback)
}

function getAccess(email, callback) {
    const url = baseUrl + "/auth"
    postJson(url, {"user": email}, callback)
}

function getObject(email, authKey, objId, callback) {
    const url = baseUrl + "/get"
    postJson(url, {"user": email, "auth_key": authKey, "obj_id": objId}, callback)
}

function getMetadata(email, authKey, objId, callback) {
    const url = baseUrl + "/get/object"
    postJson(url, {"user": email, "auth_key": authKey, "obj_id": objId}, callback)
}

function getThread(email, authKey, objId, callback) {
    const url = baseUrl + "/get/thread"
    postJson(url, {"user": email, "auth_key": authKey, "obj_id": objId}, callback)
}

function getObjects(email, authKey, kind='email', callback) {
    const url = baseUrl + "/get/objects"
    postJson(url, {"user": email, "auth_key": authKey, "kind": kind}, callback)
}

function requestRevoke(email, authKey, objId, recipient, callback) {
    const url = baseUrl + "/access/revoke"
    postJson(url, {"user": email, "auth_key": authKey, "obj_id": objId, "recipient": recipient}, callback)
}

function requestAllow(email, authKey, objId, recipient, callback) {
    const url = baseUrl + "/access/allow"
    postJson(url, {"user": email, "auth_key": authKey, "obj_id": objId, "recipient": recipient}, callback)
}

function requestReply(email, authKey, objId, linkObjId, linkObjKey, replyAll, callback) {
    const url = baseUrl + "/reply"
    postJson(url, {"user": email, "auth_key": authKey, "obj_id": objId,
        "link_obj_id": linkObjId, "link_obj_key": linkObjKey, "reply_all": replyAll}, callback)
}

function uploadReply(post, callback) {
    const url = baseUrl + "/upload"
    postJson(url, post, callback)
}

function deauthorize(email, authKey, objId, deauthorized, callback) {
    const url = baseUrl + "/update/object"
    postJson(url, {"user": email, "auth_key": authKey, "obj_id": objId, "deauthorized": deauthorized}, callback)
}

function getBaseUrl() {
    return baseUrl;
}

export {
    postJson, getAccess, getObject, getObjects, getMetadata,
    requestRevoke, requestAllow, deauthorize, requestReply,
    getBaseUrl, uploadReply, getThread, portal
}