import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "../styles";
import {LinearProgress} from "@material-ui/core";
import clsx from "clsx";
import {AttachmentRounded} from "@material-ui/icons";
import MiddleEllipsis from "react-middle-ellipsis";
import {isMobile} from 'react-device-detect';
import {getObject} from "../api";
import {decryptAccessKey, decryptFileWithDecryptedKey} from "../apireply";

class CCFile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    humanFileSize(size) {
        if (size === undefined) {
            return '0'
        }
        let i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
    };

    saveAttachment() {
        if (this.state.fileData) {
            let blob = new Blob([this.state.fileData], {type: this.props.file.content_type});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = this.props.file.filename;
            link.click();
            return true;
        } else {
            return false;
        }
    }

    downloadAttachment() {
        if (this.saveAttachment()) {
            return;
        }

        let objId = this.props.file.id;
        let emlKey = this.props.emlKey;

        if (objId === null || emlKey === null) {
            this.setState({
                empty: true,
            })
            return;
        }

        this.setState({objectId: objId, emlKey: emlKey, loadingStatus: "Downloading", loading: true});
        this.props.fetchAccessKey(objId, json => {
            if (json !== null) {
                this.setState({
                    key: json.key
                }, () => {
                    getObject(this.props.user.email, this.props.authKey, objId, json => {
                        if (json.hasOwnProperty("status") && json.status === 401) {
                            this.setState({
                                unauthorized: true,
                                loading: false,
                            });
                        } else {
                            decryptAccessKey(this.state.key, this.state.emlKey, accessKey => {
                                this.setState({accessKey: accessKey, loadingStatus: "Decrypting"}, () => {
                                    decryptFileWithDecryptedKey(json.data, accessKey, fileData => {
                                        this.updateWithFile(json, fileData);
                                    });
                                });
                            });
                        }
                    })
                })
            } else {
                this.setState({
                    key: '',
                    loading: false,
                    loadingStatus: null,
                    unauthorized: true,
                })
            }
        });
    }

    updateWithFile(json, fileData) {
        if (fileData !== null) {
            this.setState({
                object: json,
                fileData: fileData,
                loading: false,
                loadingStatus: null,
            }, () => {
                this.saveAttachment();
            })
        } else {
            this.setState({
                unauthorized: true,
                loading: false,
                loadingStatus: null,
            })
        }
    }

    renderOverlay(classes) {
        return <div className={clsx(classes.attachmentShowOnHover, classes.rowFull)}>
            <div className={classes.attachmentButton} style={{width: "100%"}} onClick={() => {
                this.downloadAttachment();
            }}>
                <span><b>Download</b></span>
            </div>
        </div>
    }

    renderAttachment(classes) {
        let key = null, size = null, filename = null, type = null;
        let file = this.props.file;
        key = file.id;
        size = file.size;
        filename = file.filename;
        type = file.content_type;
        return <div style={{position: "relative", marginRight: "8px"}} key={key} className={clsx(classes.row, classes.attachment)} onClick={() => {
            if (isMobile && file !== null) {
                this.downloadAttachment();
            }
        }
        }>
            <div className={clsx(classes.rowFull)}>
                {
                    this.state.loading && <LinearProgress style={{position: "absolute", top: 0, left: 0, right: 0}}/>
                }
                <div className={clsx(classes.row)}>
                    <AttachmentRounded className={classes.attachmentIcon}/>
                </div>
                <div style={{width: "90%"}}>
                    <div className={classes.column}>
                        <MiddleEllipsis>
                            {
                                this.state.loading &&
                                <span className={classes.attachmentTitle}>{this.state.loadingStatus ? this.state.loadingStatus : "Downloading"}</span>
                            }
                            {
                                !this.state.loading && <span className={classes.attachmentTitle}>{filename}</span>
                            }
                        </MiddleEllipsis>
                        <span className={classes.attachmentSubtitle}>{this.humanFileSize(size)}</span>
                    </div>
                </div>
            </div>
            {
                !isMobile && this.renderOverlay(classes)
            }
        </div>
    }

    render() {
        const {classes} = this.props;
        return this.renderAttachment(classes);
    }
}

export default withStyles(useStyles)(CCFile)