import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {CircularProgress, Container, Grid, Paper} from "@material-ui/core";
import {getObjects} from "./api";
import {DataGrid} from "@material-ui/data-grid";
import CCObject from "./object";
import clsx from "clsx";

class CCObjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            objects: [],
            active: null,
            kind: this.props.kind !== undefined ? this.props.kind : 'email'
        };
        this.back = this.back.bind(this);
    }

    componentDidMount() {
        getObjects(this.props.user.email, this.props.authKey, this.state.kind,json => {
            let now = new Date().getTime() / 1000.0;
            json.forEach(item => {
                let ts = json['created'] + json['expiry']
                item['expired'] = json['expiry'] !== 0 && ts <= now;
            })
            this.setState({
                loading: false,
                objects: json
            })
        })
    }

    back() {
        this.setState({
            active: null,
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        if (this.state.active) {
            return <CCObject user={this.props.user} authKey={this.props.authKey} objectId={this.state.active.id} back={this.back}/>
        }

        const columns = [
            // {field: 'owner', headerName: 'From', flex: 1,},
            {field: 'to', headerName: 'To', flex: 1,}
        ]
        if (this.state.kind === 'email') {
            columns.push({field: 'subject', headerName: 'Subject', flex: 1,})
        } else {
            columns.push({field: 'filename', headerName: 'Name', flex: 1,})
        }
        columns.push({
                field: 'created', headerName: 'Date', flex: 1, valueGetter: params => {
                    if (!params.value || params.value === 0) {
                        return "Inactive"
                    } else {
                        let date = new Date(params.value * 1000);
                        return date.toLocaleDateString() + " " + date.toLocaleTimeString()
                    }
                }
            },
            {
                field: 'expired', headerName: 'Status', flex: 1, valueGetter: params => {
                    if (params.value) {
                        return "Expired";
                    } else {
                        return "Active";
                    }
                }
            })

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <DataGrid autoHeight
                                  className={classes.paperGrid}
                                  columns={columns}
                                  rows={this.state.objects}
                                  pageSize={10}
                                  disableColumnSelector={true}
                                  onRowClick={item => {
                                      this.setState({
                                          active: item.row
                                      })
                                  }}/>
                    </Grid>
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCObjects)