import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "../styles";
import {CircularProgress, Container} from "@material-ui/core";
import {Document, Page} from "react-pdf/dist/umd/entry.webpack";
import clsx from "clsx";

class CCPDF extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            page: 1,
            numPages: 0,
        };
    }

    componentDidMount() {
        this.setState({
            loading: false,
            file: this.props.file,
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        return <div>
            <Document
                renderMode={"svg"}
                className={classes.pdf}
                file={this.state.file}
                onLoadSuccess={({numPages}) => {
                    if (numPages !== this.state.numPages) {
                        this.setState({numPages: numPages})
                    }
                }}
            >
                <Page height={800} pageNumber={this.state.page}/>
                <div className={clsx(classes.pageControls)}>
                    <div className={classes.rowFull}>
                        <button className={classes.pdfButton}
                                type="button"
                                disabled={this.state.page <= 1}
                                onClick={() => this.setState({page: this.state.page - 1})}
                        >
                            &lt;
                        </button>
                        <span className={classes.pdfSpan}>
                            {this.state.page || (this.state.numPages ? 1 : '--')} of {this.state.numPages || '--'}
                        </span>
                        <button className={clsx(classes.pdfButton)} style={{marginRight: "0px"}}
                                type="button"
                                disabled={this.state.page >= this.state.numPages}
                                onClick={() => this.setState({page: this.state.page + 1})}
                        >
                            &gt;
                        </button>
                    </div>
                </div>
            </Document>
        </div>;
    }
}

export default withStyles(useStyles)(CCPDF)