import * as React from 'react';
import './App.css';
import 'firebase/auth';
import {ThemeProvider} from '@material-ui/core/styles';
import CCDashboard from "./components/dashboard";
import WebSocketProvider from './components/pages/socket'
import {withAuth} from "./components/pages/context";
import {darkTheme} from "./components/theme";
import CCNewLogin from "./components/newlogin";

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
        }
    }

    componentDidMount() {
        this.callback = () => {
            this.setState({
                loading: this.props.auth.loading
            })
        };
        this.props.auth.addCallback(this.callback);
    }

    componentWillUnmount() {
        this.props.auth.removeCallback(this.callback);
    }

    render() {
        return (
            <React.Fragment>
                <WebSocketProvider>
                    {
                        this.props.auth.user && this.props.auth.authKey
                            ?
                            <ThemeProvider theme={darkTheme()}>
                                <CCDashboard user={this.props.auth.user}
                                             objId = {this.props.auth.objId}
                                             objKey = {this.props.auth.objKey}
                                             sendEmailVerification={this.props.auth.sendEmailVerification}
                                             signOut={this.props.auth.signOut}
                                             authKey={this.props.auth.authKey}
                                             fetchAccessKey={this.props.auth.fetchAccessKey}/>
                            </ThemeProvider>
                            :
                            <ThemeProvider theme={darkTheme()}>
                                <CCNewLogin signInWithGoogle={this.props.auth.signInWithGoogle}
                                            signInWithEmail={this.props.auth.signInWithEmail}
                                            signUpWithEmail={this.props.auth.signUpWithEmail}
                                            loading={this.state.loading}
                                            auth={this.props.auth.firebaseAppAuth}/>
                            </ThemeProvider>
                    }
                </WebSocketProvider>
            </React.Fragment>
        );
    }
}

/** Wrap it */
export default withAuth(App);
