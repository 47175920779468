(function() {
    const crypto = require('crypto');

    let _settings = {};

    let _configure_settings = function(version) {
        let settings = {
            debug: false,
            algorithm: 'aes-256-cbc',
            salt_length: 8,
            iv_length: 16,
            pbkdf2: {
                iterations: 10000,
                key_length: 32
            },
            hmac: {
                length: 32
            }
        };

        switch(version) {
            case 3:
                settings.options = 1;
                settings.hmac.includes_header = true;
                settings.hmac.algorithm = 'sha256';
                break;
            default:
                let err = "Unsupported schema version " + version;
                throw err
        }

        _settings = settings;
    };

    let _unpack_encrypted_base64_data = function(b64str) {
        let data = new Buffer(b64str, 'base64');

        let components = {
            headers: _parseHeaders(data),
            hmac: data.slice(data.length -_settings.hmac.length)
        };

        let header_length = components.headers.length;
        let cipher_text_length = data.length - header_length - components.hmac.length;

        components.cipher_text = data.slice(header_length, header_length + cipher_text_length);

        return components;
    };

    let _parseHeaders = function(buffer_data) {
        let offset = 0;

        let version_char = buffer_data.slice(offset, offset + 1);
        offset += version_char.length;

        _configure_settings(version_char.toString('binary').charCodeAt());

        let options_char = buffer_data.slice(offset, offset + 1);
        offset += options_char.length;

        let encryption_salt = buffer_data.slice(offset, offset + _settings.salt_length);
        offset += encryption_salt.length;

        let hmac_salt = buffer_data.slice(offset, offset + _settings.salt_length);
        offset += hmac_salt.length;

        let iv = buffer_data.slice(offset, offset + _settings.iv_length);
        offset += iv.length;

        return {
            version: version_char,
            options: options_char,
            encryption_salt: encryption_salt,
            hmac_salt: hmac_salt,
            iv: iv,
            length: offset
        };
    };

    let _hmac_is_valid = function(components, password) {
        const hmac_key = _generate_key(password, components.headers.hmac_salt);
        return components.hmac.equals(_generate_hmac(components, hmac_key));
    };

    let _hmac_is_valid_async = function(components, password, callback) {
        _generate_key_async(password, components.headers.hmac_salt, (err, key) => {
            callback(components.hmac.equals(_generate_hmac(components, key)));
        })
    }

    let _generate_key = function (password, salt) {
        return crypto.pbkdf2Sync(password, salt, _settings.pbkdf2.iterations, _settings.pbkdf2.key_length, 'sha1');
    };

    let _generate_key_async = function (password, salt, callback) {
        crypto.pbkdf2(password, salt, _settings.pbkdf2.iterations, _settings.pbkdf2.key_length, 'sha1', (err, key) => {
            callback(err, key);
        });
    }

    let _generate_hmac = function(components, hmac_key) {
        let hmac_message = new Buffer('');

        if (_settings.hmac.includes_header) {
            hmac_message = Buffer.concat([
                hmac_message,
                components.headers.version,
                components.headers.options,
                components.headers.encryption_salt || new Buffer(''),
                components.headers.hmac_salt || new Buffer(''),
                components.headers.iv
            ]);
        }

        hmac_message = Buffer.concat([hmac_message, components.cipher_text]);

        return crypto.createHmac(_settings.hmac.algorithm, hmac_key).update(hmac_message).digest();
    };

    let _generate_initialized_components = function(version) {
        return {
            headers: {
                version: new Buffer(String.fromCharCode(version)),
                options: new Buffer(String.fromCharCode(_settings.options))
            }
        };
    };

    let _generate_salt = function() {
        return _generate_iv(_settings.salt_length);
    };

    let _generate_iv = function (block_size) {
        return crypto.randomBytes(block_size)
    };

    let _encrypt = function(plain_text, components, encryption_key, hmac_key) {
        const cipher = crypto.createCipheriv(_settings.algorithm, encryption_key, components.headers.iv)
        components.cipher_text = Buffer.concat([cipher.update(plain_text), cipher.final()])

        let data = Buffer.concat([
            components.headers.version,
            components.headers.options,
            components.headers.encryption_salt || new Buffer(''),
            components.headers.hmac_salt || new Buffer(''),
            components.headers.iv,
            components.cipher_text
        ]);

        let hmac = _generate_hmac(components, hmac_key);

        return Buffer.concat([data, hmac]).toString('base64');
    };

    let timeStart = function (msg) {
        if (_settings.debug) {
            console.time(msg);
        }
    }

    let timeEnd = function (msg) {
        if (_settings.debug) {
            console.timeEnd(msg);
        }
    }

    let CCCryptor = {};

    CCCryptor.GenerateKey = _generate_key;

    CCCryptor.Encrypt = function(plain_text, password, version) {
        version || (version = 3);
        Buffer.isBuffer(plain_text) || (plain_text = new Buffer(plain_text, 'binary'));
        Buffer.isBuffer(password) || (password = new Buffer(password, 'binary'));

        _configure_settings(version);

        let components = _generate_initialized_components(version);
        components.headers.encryption_salt = _generate_salt();
        components.headers.hmac_salt = _generate_salt();
        components.headers.iv = _generate_iv(_settings.iv_length);

        let encryption_key = _generate_key(password, components.headers.encryption_salt);
        let hmac_key = _generate_key(password, components.headers.hmac_salt);

        return _encrypt(plain_text, components, encryption_key, hmac_key);
    };

    CCCryptor.EncryptWithArbitrarySalts = function(plain_text, password, encryption_salt, hmac_salt, iv, version) {
        version || (version = 3);
        Buffer.isBuffer(plain_text) || (plain_text = new Buffer(plain_text, 'binary'));
        Buffer.isBuffer(password) || (password = new Buffer(password));
        Buffer.isBuffer(encryption_salt) || (encryption_salt = new Buffer(encryption_salt, 'binary'));
        Buffer.isBuffer(hmac_salt) || (hmac_salt = new Buffer(hmac_salt, 'binary'));
        Buffer.isBuffer(iv) || (iv = new Buffer(iv, 'binary'));

        _configure_settings(version);

        let components = _generate_initialized_components(version);
        components.headers.encryption_salt = encryption_salt;
        components.headers.hmac_salt = hmac_salt;
        components.headers.iv = iv;

        let encryption_key = _generate_key(password, encryption_salt);
        let hmac_key = _generate_key(password, hmac_salt);

        return _encrypt(plain_text, components, encryption_key, hmac_key);
    };

    CCCryptor.EncryptWithArbitraryKeys = function (plain_text, encryption_key, hmac_key, iv, version) {
        version || (version = 3);
        Buffer.isBuffer(plain_text) || (plain_text = new Buffer(plain_text, 'binary'));
        Buffer.isBuffer(encryption_key) || (encryption_key = new Buffer(encryption_key, 'binary'));
        Buffer.isBuffer(hmac_key) || (hmac_key = new Buffer(hmac_key, 'binary'));
        Buffer.isBuffer(iv) || (iv = new Buffer(iv, 'binary'));

        _settings.options = 0;

        let components = _generate_initialized_components(version);
        components.headers.iv = iv;

        return _encrypt(plain_text, components, encryption_key, hmac_key);
    };

    CCCryptor.Decrypt = function(b64str, password, callback) {
        timeStart('\tUnpacking');
        let components = _unpack_encrypted_base64_data(b64str);
        timeEnd('\tUnpacking');
        Buffer.isBuffer(password) || (password = new Buffer(password, 'binary'));

        timeStart('\tHMAC');
        _hmac_is_valid_async(components, password, isValid => {
            timeEnd('\tHMAC');
            if (!isValid) {
                callback(null);
                return;
            }
            _generate_key_async(password, components.headers.encryption_salt, (err, key) => {
                timeStart('\tCreating Decipher');
                const decipher = crypto.createDecipheriv(_settings.algorithm, key, components.headers.iv)
                timeEnd('\tCreating Decipher');
                CCCryptor.DecryptHelper(decipher, components.cipher_text, 0, new Buffer(''), callback);
            })
        })
    };

    CCCryptor.DecryptHelper = function(decipher, cipher_text, currOffset, decrypted, callback) {
        let length = cipher_text.length;
        let batchSize = 1024 * 1024;
        timeStart('\tDecipher Step');
        if (currOffset + batchSize >= length) {
            let data = cipher_text.slice(currOffset, length);
            let part = decipher.update(data);
            decrypted = Buffer.concat([decrypted, part, decipher.final()]);
            timeEnd('\tDecipher Step');
            callback(decrypted);
        } else {
            let data = cipher_text.slice(currOffset, currOffset + batchSize);
            let part = decipher.update(data);
            decrypted = Buffer.concat([decrypted, part]);
            timeEnd('\tDecipher Step');
            setImmediate(() => {
                CCCryptor.DecryptHelper(decipher, cipher_text, currOffset + batchSize, decrypted, callback);
            })
        }
    }

    module.exports = CCCryptor;
})();