import React from "react";
import {Container, Grid, Paper} from "@material-ui/core";
import clsx from "clsx";

function renderTo(classes, to, self, showAll, showAllCallback) {
    if (to == null || to.length === 0) {
        return "";
    }

    let last = null;
    if (to.length > 0) {
        last = to[to.length - 1]
    }

    let renderTo;
    if (showAll) {
        renderTo = to.map(item => {
            if (item === last) {
                return <span>{item}</span>
            } else {
                return <span>{item + ", "}</span>
            }
        })
    } else {
        if (to.includes(self)) {
            if (to.length === 1) {
                renderTo = "Me"
            } else {
                let suffix = to.length === 2 ? " other" : " others";
                renderTo = <span className={classes.url}
                                 onClick={() => showAllCallback()}>Me & {String(to.length - 1) + suffix}</span>
            }
        } else {
            if (to.length === 1) {
                renderTo = to[0];
            } else {
                let suffix = to.length === 2 ? " other" : " others";
                renderTo = <span className={classes.url}
                                 onClick={() => showAllCallback()}>{to[0]} & {String(to.length - 1) + suffix}</span>
            }
        }
    }
    return renderTo;
}

function renderPaper(classes, item, wrap = false) {
    const inner = <Paper className={wrap ? clsx(classes.paper, classes.paperLight) : classes.paperLight} style={{overflow: "hidden", width: "100%"}}>
        <div className={classes.paperNarrow} style={{height: "200px"}}>
            <div className={classes.rowFull} style={{justifyContent: "center", height: "100%"}}>
                <div className={classes.row}>
                    {
                        item
                    }
                </div>
            </div>
        </div>
    </Paper>;

    if (wrap) {
        return <Container className={classes.root}>
            {
                inner
            }
        </Container>;
    } else {
        return <Grid item xs={12}>
            {
                inner
            }
        </Grid>;
    }
}

export {
    renderTo, renderPaper
}