import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "../styles";
import {Button, CircularProgress, Container, Grid} from "@material-ui/core";
import {getThread} from "../api";
import {renderPaper} from "../to";
import CCMessage from "./message";
import {decryptEmlKey} from "../apireply";
import Typography from "@material-ui/core/Typography";

class CCThread extends Component {
    constructor(props) {
        super(props);
        this.baseLimit = 5;
        this.state = {
            empty: false,
            loading: true,
            unauthorized: false,
            limit: this.baseLimit,
        };
        this.refresh = this.refresh.bind(this);
    }

    componentDidMount() {
        let objId = this.props.objectId;
        let objKey = this.props.objectKey;
        if (objId === null || objKey === null) {
            this.setState({
                empty: true,
            })
            return;
        }
        let emlKey = decryptEmlKey(objKey);
        this.setState({
            emlKey: emlKey
        }, () => {
            this.refresh();
        })
    }

    refresh(limitIncrement = 0) {
        let objectId = this.props.objectId;
        this.setState({
            loading: true,
            limit: this.state.limit + limitIncrement
        }, () => {
            getThread(this.props.user.email, this.props.authKey, objectId, json => {
                if (json.hasOwnProperty('status') && json['status'] === 401) {
                    this.setState({
                        unauthorized: true,
                    })
                    return;
                }
                let emails = []
                json.forEach(item => {
                    emails.push(item);
                })
                if (emails.length === 0) {
                    this.setState({
                        unauthorized: true,
                    })
                } else {
                    this.setState({
                        emails: emails,
                        loading: false,
                    })
                }
            })
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.empty) {
            return renderPaper(classes, <div className={classes.row}>
                {
                    <span className={classes.paperFont}>No email selected.</span>
                }
            </div>);
        }

        if (this.state.unauthorized) {
            return renderPaper(classes, <div className={classes.row}>
                <span className={classes.paperFont}>You do not have access to this content or this content has expired.</span>
            </div>)
        }

        if (this.state.loading) {
            return renderPaper(classes, <div className={classes.row}>
                <CircularProgress style={{marginRight: "20px"}}/>
                <span className={classes.paperFont}>Fetching</span>
            </div>, true);
        }

        let emails = this.state.emails;
        let limit = this.state.limit;
        let emailsHtml = []
        for (let i = 0; i < this.state.emails.length && i < limit; i++) {
            let item = emails[i];
            emailsHtml.push(<CCMessage classes={classes}
                                       user={this.props.user} authKey={this.props.authKey}
                                       fetchAccessKey={this.props.fetchAccessKey}
                                       objectId={item.id}
                                       object={item}
                                       emlKey={this.state.emlKey}
                                       refresh={this.refresh}/>)
        }
        if (emails.length > limit) {
            let remaining = emails.length - limit;
            let nextLoad = Math.min(remaining, this.baseLimit);
            emailsHtml.push(<Grid item xs={12}>
                <div className={classes.rowSm} style={{alignItems: "center"}}>
                    <Button variant={"outlined"} className={classes.button} onClick={() => {
                        this.setState({
                            limit: limit + this.baseLimit
                        })
                    }}>
                        Load {nextLoad} more {nextLoad > 1 ? "emails" : "email"}
                    </Button>
                    {
                        <Typography className={classes.remainingItem} style={{marginLeft: "10px"}} variant={"body2"}>{"out of " + String(remaining) + " older " + (remaining > 1 ? "emails" : "email") + " in this conversation."}</Typography>
                    }
                </div>
            </Grid>)
        }

        let ret = []
        ret.push(<Typography className={classes.activeTitle} variant="h1"><b>{this.state.emails[0].subject}</b></Typography>);
        ret.push(<Container className={classes.root}>
            <Grid container spacing={2}>
                {
                    emailsHtml
                }
            </Grid>
        </Container>);
        return ret;
    }
}

export default withStyles(useStyles)(CCThread);