import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "./styles";
import {Button, CircularProgress, Container, Divider, Grid, Paper, Typography} from "@material-ui/core";
import {ChevronLeft} from "@material-ui/icons";
import clsx from "clsx";
import {deauthorize, getMetadata, requestAllow, requestRevoke} from "./api";

class CCObject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
        this.toggleAuthorization = this.toggleAuthorization.bind(this);
    }

    refresh() {
        getMetadata(this.props.user.email, this.props.authKey, this.props.objectId, json => {
            this.setState({
                object: json,
                loading: false,
            })
        })
    }

    componentDidMount() {
        this.refresh()
    }

    isDeauthorized() {
        if (this.state.object.hasOwnProperty('deauthorized')) {
            return this.state.object.deauthorized;
        } else {
            return false;
        }
    }

    isExpired() {
        if (this.state.object.hasOwnProperty('expiry')) {
            let expiry = this.state.object.expiry;
            expiry = new Date(expiry * 1000.0);
            if (expiry === 0) {
                return false;
            }
            return expiry > new Date()
        } else {
            return false;
        }
    }

    toggleAuthorization() {
        this.setState({
            loading: true,
        }, () => {
            deauthorize(this.props.user.email, this.props.authKey, this.props.objectId,
                !this.isDeauthorized(), () => {
                    this.refresh();
                })
        })
    }

    render() {
        const {classes} = this.props;

        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }

        let date = new Date(this.state.object.created * 1000.0);
        let dateStr = date.toLocaleDateString() + " at " + date.toLocaleTimeString();

        let name = null;
        if (this.state.object.hasOwnProperty('filename')) {
            name = this.state.object.filename;
        } else {
            name = this.state.object.subject;
        }

        return <Container className={classes.root}>
            <Paper className={clsx(classes.paper, classes.paperLight)}>
                <Button onClick={() => this.props.back()}><ChevronLeft/></Button>
                <Grid container spacing={3} className={classes.paper}>
                    <Grid item xs={12} className={classes.rowFull}>
                        <Typography variant="h4">{name}</Typography>
                        <Button className={clsx(classes.right,
                            this.isDeauthorized() ? classes.button : classes.buttonRed)} onClick={
                            () => {
                                this.toggleAuthorization();
                            }
                        }>{this.isDeauthorized() ? "Re-Authorize" : "Revoke"}</Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography>
                            Created by <b>{this.state.object.owner}</b> on {dateStr}
                        </Typography>
                    </Grid>
                    {this.state.object.to && this.state.object.to.length > 0 &&
                        <Grid item xs={12} style={{height: "1px"}}>
                            <Divider flexItem orientation="horizontal" style={{height: "1px"}}/>
                        </Grid>
                    }
                    {this.state.object.to && this.state.object.to.length > 0 &&
                        <Grid item xs={12}>
                            <Typography variant="h6">Allowed Access:</Typography>
                        </Grid>
                    }
                    {this.state.object.to && this.state.object.to.map(item => {
                        return <Grid item xs={12} className={classes.rowFull}>
                            {/*<Chip label={item} variant="outlined"/>*/}
                            <Typography>{item}</Typography>
                            <Button className={clsx(classes.right, classes.buttonRed)} onClick={
                                () => {
                                    this.setState({
                                        loading: true,
                                    }, () => {
                                        requestRevoke(this.props.user.email, this.props.authKey, this.props.objectId, item, json => {
                                            this.refresh();
                                        })
                                    })
                                }
                            }>Remove Access</Button>
                        </Grid>
                    })}
                    {
                        this.state.object.revoked && this.state.object.revoked.length > 0 &&
                        <Grid item xs={12} style={{height: "1px"}}>
                            <Divider flexItem orientation="horizontal" style={{height: "1px"}}/>
                        </Grid>
                    }
                    {this.state.object.revoked && this.state.object.revoked.length > 0 &&
                        <Grid item xs={12}>
                            <Typography variant="h6">Removed Access:</Typography>
                        </Grid>
                    }
                    {this.state.object.revoked && this.state.object.revoked.map(item => {
                        return <Grid item xs={12} className={classes.rowFull}>
                            {/*<Chip label={item} variant="outlined"/>*/}
                            <Typography>{item}</Typography>
                            <Button className={clsx(classes.right, classes.button)} onClick={
                                () => {
                                    this.setState({
                                        loading: true,
                                    }, () => {
                                        requestAllow(this.props.user.email, this.props.authKey, this.props.objectId, item, json => {
                                            this.refresh();
                                        })
                                    })
                                }
                            }>Allow</Button>
                        </Grid>
                    })}
                </Grid>
            </Paper>
        </Container>;
    }
}

export default withStyles(useStyles)(CCObject)