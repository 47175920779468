import {withStyles} from "@material-ui/core/styles";
import React, {Component} from "react";
import {useStyles} from "../styles";
import {CircularProgress, Container} from "@material-ui/core";
import clsx from "clsx";
import {AttachmentRounded} from "@material-ui/icons";
import MiddleEllipsis from "react-middle-ellipsis";
import {isMobile} from 'react-device-detect';

class CCAttachment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    componentDidMount() {
        this.setState({
            loading: false
        })
    }

    humanFileSize(size) {
        let i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
        return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i];
    };

    isAttachment() {
        return this.props.attachment !== undefined && this.props.attachment !== null;
    }

    downloadAttachment() {
        if (this.isAttachment()) {
            let attachment = this.props.attachment;
            let blob = new Blob([attachment.content], {type: attachment.contentType});
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = attachment.filename;
            link.click();
        } else {
            let file = this.props.file;
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            link.download = file.name;
            link.click();
        }
    }

    renderOverlay(classes) {
        if (this.isAttachment()) {
            let attachment = this.props.attachment;
            return <div className={clsx(classes.attachmentShowOnHover, classes.rowFull)}>
                <div className={classes.attachmentButton} onClick={() => {
                    this.props.view(attachment);
                }}>
                    <span><b>View</b></span>
                </div>
                <div className={classes.attachmentButton} onClick={() => {
                    this.downloadAttachment();
                }}>
                    <span><b>Download</b></span>
                </div>
            </div>
        } else {
            return <div className={clsx(classes.attachmentShowOnHover, classes.rowFull)}>
                <div className={classes.attachmentButton} onClick={() => {
                    this.downloadAttachment();
                }}>
                    <span><b>View</b></span>
                </div>
                <div className={classes.attachmentButton} onClick={() => {
                    this.props.remove(this.props.file);
                }}>
                    <span><b>Remove</b></span>
                </div>
            </div>
        }
    }

    renderAttachment(classes) {
        let key = null, size = null, filename = null, type = null;
        let attachment = this.props.attachment;
        if (this.isAttachment()) {
            key = attachment.contentId;
            size = attachment.size;
            filename = attachment.filename;
            type = attachment.contentType;
        } else {
            let file = this.props.file;
            key = file.name;
            size = file.size;
            filename = file.name;
            type = file.type;
        }
        return <div style={{position: "relative", marginRight: "8px"}} key={key} className={clsx(classes.row, classes.attachment)} onClick={() => {
            if (isMobile && attachment !== null) {
                this.downloadAttachment();
            }
        }
        }>
            <div className={clsx(classes.rowFull)}>
                <div className={clsx(classes.row)}>
                    <AttachmentRounded className={classes.attachmentIcon}/>
                </div>
                <div style={{width: "90%"}}>
                    <div className={classes.column}>
                        <MiddleEllipsis>
                            <span className={classes.attachmentTitle}>{filename}</span>
                        </MiddleEllipsis>
                        <span className={classes.attachmentSubtitle}>{this.humanFileSize(size)}</span>
                    </div>
                </div>
            </div>
            {
                !isMobile && this.renderOverlay(classes)
            }
        </div>
    }

    render() {
        const {classes} = this.props;
        if (this.state.loading) {
            return <Container className={classes.rootFull}><CircularProgress/></Container>;
        }
        return this.renderAttachment(classes);
    }
}

export default withStyles(useStyles)(CCAttachment)