import firebase from 'firebase/app';
import 'firebase/auth'
import 'firebase/firestore'
import config from "../../config";
import React, {createContext} from "react";
import {getAccess, portal} from "./api";
import {console_log} from "./log";

class AuthHandler {
    constructor() {
        this.callbacks = [];
        this.loading = true;
        this.user = null;
        this.isSignedIn = false;
        this.authKey = null;
        this.objId = null;
        this.objKey = null;
        this.firebaseApp = firebase.initializeApp(config);
        this.firebaseAppAuth = this.firebaseApp.auth();
        this.setIsSignedIn(this.firebaseAppAuth.currentUser);
        this.firebaseAppAuth.onAuthStateChanged(user => {
            this.setIsSignedIn(user);
        });

        this.setLoading = this.setLoading.bind(this);
        this.setIsSignedIn = this.setIsSignedIn.bind(this);
        this.signInWithGoogle = this.signInWithGoogle.bind(this);
        this.signInWithEmail = this.signInWithEmail.bind(this);
        this.signUpWithEmail = this.signUpWithEmail.bind(this);
        this.sendEmailVerification = this.sendEmailVerification.bind(this);
        this.signOut = this.signOut.bind(this);
        this.fetchAuthKey = this.fetchAuthKey.bind(this);
        this.fetchAccessKey = this.fetchAccessKey.bind(this);
        this.addCallback = this.addCallback.bind(this);
        this.removeCallback = this.removeCallback.bind(this);
        this.callCallbacks = this.callCallbacks.bind(this);
    }

    setLoading(loading) {
        this.loading = loading;
        this.callCallbacks();
    }

    setIsSignedIn(user) {
        this.user = user;
        this.isSignedIn = !!user;
        console_log('Sign in updated: ', this.isSignedIn)
        this.saveSearchIfNeeded();
        if (this.isSignedIn) {
            this.restoreSearchIfNeeded();
            this.fetchAuthKey();
        } else {
            this.signInWithEmailLink();
        }
    }

    signInWithEmailLink() {
        if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
            let email = window.localStorage.getItem('verificationEmail');
            if (!email) {
                this.setLoading(false);
            } else {
                firebase.auth().signInWithEmailLink(email, window.location.href)
                    .then((result) => {
                        window.localStorage.removeItem('verificationEmail');
                    })
                    .catch((error) => {
                        this.setLoading(false);
                    });
            }
        } else {
            this.setLoading(false);
        }
    }

    signInWithGoogle() {
        console_log('Signing in')
        let provider = new firebase.auth.GoogleAuthProvider();
        this.setLoading(true);
        this.firebaseAppAuth.signInWithRedirect(provider).then(() => {

        });
    }

    signInWithEmail(email, callback) {
        this.signUpWithEmail(email, callback)
    }

    signUpWithEmail(email, callback) {
        console_log('Signing up with email')
        this.setLoading(true);
        portal(email, () => {
            window.localStorage.setItem('verificationEmail', email);
            console_log('Calling callback')
            callback()
            this.setLoading(false);
        })
    }

    hasReadableUrl() {
        let search = window.location.search;
        if (search !== null && search !== undefined && search.length > 0) {
            const urlSearchParams = new URLSearchParams(search);
            const params = Object.fromEntries(urlSearchParams.entries());
            if (!params.hasOwnProperty('obj_id')) {
                return null;
            }
            if (!params.hasOwnProperty('obj_key')) {
                return null;
            }
            return search;
        } else {
            return null;
        }
    }

    saveSearchIfNeeded() {
        let readable = this.hasReadableUrl();
        if (readable) {
            if (window.localStorage.getItem('verificationSearch') !== readable) {
                console_log('Saving search: ', readable);
                window.localStorage.setItem('verificationSearch', readable)
                window.localStorage.setItem('verificationSearchExpiry', (new Date()).getTime());
            } else {
                this.expireSearch();
            }
        } else {
            this.expireSearch();
        }
    }

    updateReaderValues(search) {
        if (search !== null && search !== undefined && search.length > 0) {
            const urlSearchParams = new URLSearchParams(search);
            const params = Object.fromEntries(urlSearchParams.entries());
            if (params.hasOwnProperty('obj_id')) {
                this.objId = params['obj_id']
            }
            if (params.hasOwnProperty('obj_key')) {
                this.objKey = params['obj_key'];
            }
        }
    }

    restoreSearchIfNeeded() {
        let readable = this.hasReadableUrl();
        if (readable !== null) {
            this.updateReaderValues(readable);
            return;
        }
        let search = window.localStorage.getItem('verificationSearch');
        if (search === null || search === undefined || search.length === 0) {
            return;
        }
        console_log('Restoring search: ', search);
        this.updateReaderValues(search);
    }

    expireSearch() {
        let time = window.localStorage.getItem('verificationSearchExpiry');
        let now = (new Date()).getTime();
        if ((now - time) >= (24 * 60 * 60 * 1000)) {
            this.removeSearch();
        }
    }

    removeSearch() {
        console_log('Removing search');
        window.localStorage.removeItem('verificationSearch');
        window.localStorage.removeItem('verificationSearchExpiry')
    }

    sendEmailVerification() {
        console_log('Sending verification email')
        this.setLoading(true);
        this.user.sendEmailVerification().then(() => {
            this.setLoading(false);
        }).catch((error) => {
            console_log(error);
            this.setLoading(false);
        });
    }

    signOut() {
        this.removeSearch();
        this.firebaseAppAuth.signOut();
    }

    doc() {
        return firebase.firestore().collection("users").doc(this.user.email);
    }

    fetchAuthKey() {
        if (this.isSignedIn) {
            console_log('Fetching auth key')
            this.doc().get().then(doc => {
                let auth_key = doc.exists ? doc.get('auth_key') : null
                if (auth_key) {
                    console_log('Got auth key')
                    this.authKey = auth_key;
                    this.setLoading(false);
                } else {
                    console_log('No auth key, requesting new')
                    getAccess(this.user.email, () => {
                        this.fetchAuthKey()
                    })
                }
            })
        } else {
            this.setLoading(false);
        }
    }

    fetchAccessKey(objId, callback) {
        if (this.isSignedIn) {
            this.doc().collection("objects").doc(objId).get().then(doc => {
                if (doc.exists) {
                    callback(doc.data());
                } else {
                    callback(null);
                }
            })
        } else {
            callback(null);
        }
    }

    addCallback(callback) {
        this.callbacks.push(callback);
    }

    removeCallback(callback) {
        this.callbacks = this.callbacks.filter(item => item !== callback);
    }

    callCallbacks() {
        this.callbacks.forEach(callback => {
            callback();
        })
    }
}

const auth = new AuthHandler()

const AuthContext = createContext(auth)

export { AuthContext }

const authWrapper = ({ children }) => {
    return (
        <AuthContext.Provider value={{auth}}>
            {children}
        </AuthContext.Provider>
    )
};

export default authWrapper;