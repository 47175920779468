import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import {withStyles} from '@material-ui/core/styles';
import CCAppBar from "./appbar";
import {CircularProgress} from "@material-ui/core";
import {withWebSocket} from "./pages/context";
import {useStyles} from "./pages/styles";
import CCFooter from "./pages/footer";
import CCSignIn from "./pages/login/signin";
import CCSignInEmail from "./pages/login/signinemail";
import CCSignUpEmail from "./pages/login/signupemail";
import CCCheckEmail from "./pages/login/checkemail";

class CCNewLogin extends React.Component<{}> {
    constructor() {
        super();
        this.state = {
            loginState: 0 // 0: root, 1: sign in, 2: sign up, 3: check email
        }
        this.setLoginState = this.setLoginState.bind(this);
    }

    setLoginState(state) {
        this.setState({
            loginState: state
        });
    }

    login() {
        return <CCSignIn signInWithGoogle={this.props.signInWithGoogle}
                         showSignInWithEmail={() => {this.setLoginState(1)}}
                         showSignUpWithEmail={() => {this.setLoginState(2)}}/>
    }

    loginWithEmail() {
        return <CCSignInEmail signInWithGoogle={this.props.signInWithGoogle}
                              signInWithEmail={(email) => this.props.signInWithEmail(email, () => {
                                  this.setLoginState(3)
                              })}
                              showSignUpWithEmail={() => {this.setLoginState(2)}}/>
    }

    signUpWithEmail() {
        return <CCSignUpEmail signInWithGoogle={this.props.signInWithGoogle}
                              signUpWithEmail={(email) => this.props.signUpWithEmail(email, () => {
                                  this.setLoginState(3)
                              })}
                              showSignInWithEmail={() => {this.setLoginState(1)}}/>
    }

    render() {
        const {classes} = this.props;
        const {window} = this.props;

        const loading = this.props.loading;

        return (
            <div className={classes.dashboardRoot}>
                <CssBaseline/>
                <CCAppBar position="fixed" className={classes.appBar}
                          orgName={this.state.org_name}/>

                <main className={classes.content} style={{minHeight: "100vh", display: "flex", flexDirection: "column"}}>
                    <div className={classes.toolbar}/>
                    <div className={classes.column} style={{flexGrow: 1, display: "flex", flexDirection: "column",
                        alignItems: "center", justifyContent: "center"}}>
                        <div style={{display: "flex", flexDirection: "column"}}>
                            {
                                loading && <CircularProgress/>
                            }
                            {
                                !loading && this.state.loginState === 0 && this.login()
                            }
                            {
                                !loading && this.state.loginState === 1 && this.loginWithEmail()
                            }
                            {
                                !loading && this.state.loginState === 2 && this.signUpWithEmail()
                            }
                            {
                                !loading && this.state.loginState === 3 && <CCCheckEmail/>
                            }
                        </div>
                    </div>
                    <CCFooter/>
                </main>
            </div>
        );
    }
}

export default withWebSocket(withStyles(useStyles)(CCNewLogin))
